import React, { useState, Suspense } from 'react';
import { bool, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/button';
import { Col, Heading, Image } from '@thd-olt-component-react/core-ui';
import { useReviewsDeprecated, useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { usePresentation } from '../../context/PresentationProvider';
import { ReviewStatsModel } from '../models/ReviewStatsModel';
import { dataModel } from '../ReviewDataModel';
import * as analytics from '../../ratings-reviews-analytics';
import './WriteAReview.styles.scss';

// eslint-disable-next-line max-len
const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
// eslint-disable-next-line max-len
const WriteAReviewForm = React.lazy(() => import(/* webpackChunkName: "write-a-review-form" */ './components/WriteAReviewForm/WriteAReviewForm')
  .then((module) => ({ default: module.WriteAReviewForm })));

export const WriteAReview = ({
  apiKey, className, itemId, link, reviewStatistics, totalReviews, triggerOverlay, disableOverlayOnClick
}) => {
  const { useCondensedLayout } = usePresentation();
  const [overlayOpen, setOverlayOpen] = useState(!!triggerOverlay);
  const variables = {
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null
    },
    searchTerm: null,
    sortBy: 'photoreview',
    startIndex: 1,
    excludeFamily: false
  };
  const { data, error, loading } = useProductDeprecated({ itemId, domains: ['identifiers', 'media'] });
  const reviewResponse = useReviewsDeprecated({ itemId, variables }) || {};
  const nullProduct = (error && !data) || !data || loading;
  const nullReviews = (reviewResponse.error && !reviewResponse.data) || !reviewResponse.data || reviewResponse.loading;

  if (nullProduct || nullReviews) {
    return null;
  }

  const { reviews } = (reviewResponse || {}).data || {};
  const { product } = data || {};
  const reviewStats = reviewStatistics || new ReviewStatsModel(reviews);
  const { totalReviewCount } = reviewStats;
  const linkClasses = classNames('u__default-link', className, 'write-review-content__button-link');
  const reviewsClass = classNames(className, {
    'write-review-content__submit__desktop__button': totalReviewCount,
    'write-review-content__submit__desktop__button-no-reviews': !totalReviewCount,
  });

  const buttonText = (totalReviewCount && totalReviewCount > 0) || (totalReviews > 0)
    ? 'Write a Review'
    : 'Write the First Review';

  const writeAReviewClick = () => {
    setOverlayOpen(true);
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'zone a',
      value: 'write a review',
    });
  };

  const handleOverlayOnClick = disableOverlayOnClick ? null : () => setOverlayOpen(false);

  return (
    <div className={!link ? 'write-review-content__submit__desktop_content' : ''} data-component="WriteAReview">
      <Button outline className={link ? linkClasses : reviewsClass} onClick={writeAReviewClick} link={link}>
        {buttonText}
      </Button>
      {overlayOpen && (
        <Suspense fallback={(<div />)}>
          <Overlay onClose={handleOverlayOnClick} open={overlayOpen}>
            {useCondensedLayout
              && (
                <>
                  <Button
                    link
                    className="content-card__overlay-back content-card__overlay"
                    onClick={() => setOverlayOpen(false)}
                    data-testid="WriteAReviewOverlay"
                  >
                    <span>
                      <Image
                        className="content-card__overlay-caret"
                        src="https://assets.homedepot-static.com/images/v1/back-arrow-orange.svg"
                        alt="Back"
                        lazy
                      />
                    </span>
                    Back
                  </Button>
                  <Heading className="content-card__overlay-title" underline>{buttonText}</Heading>
                </>
              )}
            <Col flatten className="content-card__overlay-content" data-component="WriteAReviewForm">
              <Suspense fallback={(<div />)}>
                <WriteAReviewForm
                  apiKey={apiKey}
                  closeOverlay={() => setOverlayOpen(false)}
                  headerText={buttonText}
                  itemId={itemId}
                  product={product}
                  reviewStatistics={reviewStats}
                />
              </Suspense>
            </Col>
          </Overlay>
        </Suspense>
      )}
    </div>
  );
};

WriteAReview.displayName = 'WriteAReview';

WriteAReview.propTypes = {
  apiKey: string.isRequired,
  className: string,
  itemId: string.isRequired,
  link: bool,
  reviewStatistics: shape({}),
  totalReviews: string,
  triggerOverlay: bool,
  disableOverlayOnClick: bool
};

WriteAReview.defaultProps = {
  className: null,
  link: false,
  reviewStatistics: null,
  totalReviews: null,
  triggerOverlay: false,
  disableOverlayOnClick: false
};

WriteAReview.dataModel = dataModel;
