export const getProductReviews = ({
  reviewModels, searchText, selected, initialReviews, itemId, storeId
}) => {
  const hasReviewModels = reviewModels.length || searchText || selected.length;
  let id = itemId || storeId;
  return hasReviewModels ? reviewModels : (initialReviews.current[id] || []);
};

export const onFilterChange = (updatedFilters, { dispatch, useCondensedLayout = false, category = 'product' }) => {
  dispatch({ type: 'filter', value: updatedFilters });
  const searchAndSort = document.getElementById('search-and-sort');
  if (useCondensedLayout) {
    const isHistogramClick = Number.isInteger(updatedFilters);
    const overlay = document.querySelector('.content-card__overlay');
    const backButton = document.getElementsByClassName('content-card__overlay-back');
    const offsetHeight = backButton
      ? document.getElementsByClassName('content-card__overlay-back')[0]?.offsetHeight : 0;
    let scrollToPosition = searchAndSort.getBoundingClientRect()?.top - offsetHeight;

    if (overlay && overlay.scrollTo && isHistogramClick) {
      overlay.scrollTo({ behavior: 'smooth', top: scrollToPosition });
    } else {
      const filters = document.querySelector('.ratings-and-reviews__search-and-filter');
      scrollToPosition = filters.getBoundingClientRect()?.top + window.scrollY;
      window.scrollTo({ behavior: 'smooth', top: scrollToPosition });
    }
  } else {
    const stickyNav = document.getElementById('sticky-nav');
    const currentPosition = window.scrollY || window.pageYOffset;
    if (category === 'product') {
      const scrollToPosition = searchAndSort.getBoundingClientRect().top - stickyNav.offsetHeight + currentPosition;
      window.scroll({ behavior: 'smooth', top: scrollToPosition });
    }
  }
};

export const badgesFilterLogic = (badgesOrder) => {
  let badges = [...badgesOrder];

  if (badges.includes('earlyReviewerIncentive')) {
    // Home Depot Reviewer Program can't be Seed Reviews
    badges = badges.filter((badge) => badge !== 'incentivizedReview');

    // All Home Depot Reviewer Programs are Verified Purchasers
    if (badges.includes('verifiedPurchaser') === false) {
      badges.push('verifiedPurchaser');
    }
  }

  if (badges.includes('incentivizedReview')) {
    // Seed reviews can't be Verified Purchasers
    badges = badges.filter((badge) => badge !== 'verifiedPurchaser');
  }

  return badges;
};

export const getFilterText = (checkbox) => {
  if (checkbox.value === 'verified') {
    return 'Verified Purchases';
  }
  if (checkbox.value === 'current_item_selections') {
    return 'Current Item Selections';
  }
  return `${checkbox.value} Star`;
};
